<template>
  <div v-if="hoverText">
    <span
      class="tooltip__text"
      v-if="hoverText.length > subString"
      :class="{
        tooltipTop: position == 'top',
        tooltipRight: position == 'right',
        tooltipBottom: position == 'bottom',
        tooltipLeft: position == 'left',
      }"
      v-html="hoverText"></span
    >
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    hoverText: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "top",
    },
    subString: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  .tooltip__text {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 3.5px 7px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    word-break: break-word;
  }
  &:hover .tooltip__text {
    visibility: visible;
    opacity: 1;
    white-space: pre-wrap;
    min-width: 60px;
    max-width: 200px;
  }
  .tooltipTop {
    bottom: 125%;
    left: 0;
    width: max-content;
    min-width: 130px !important;
    max-width: 235px !important;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 15%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  .tooltipRight {
    top: 2px;
    left: 100%;
    width: max-content;
    min-width: 130px !important;
    max-width: 500px !important;
    bottom: auto;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #555 transparent transparent;
    }
  }
  .tooltipBottom {
    top: 135%;
    left: 40%;
    margin-left: -80px;
    width: max-content;
    min-width: 130px !important;
    max-width: 200px !important;
    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #555 transparent;
    }
  }
  .tooltipLeft {
    top: 2px;
    bottom: auto;
    right: 106%;
    width: max-content;
    min-width: 130px !important;
    max-width: 500px !important;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #555;
    }
  }
}
.modal-body {
  .tooltipRight {
    top: -5px;
  }
  .products {
    .tooltipLeft {
      top: 8px;
    }
    .tooltipRight {
      top: 8px;
    }
  }
}
.filters__item {
  .tooltip {
    .tooltipTop {
      margin-left: -20px !important;
    }
  }
}
.bar.tooltip {
  .tooltipRight {
    top: -15px;
    left: 120%;
    min-width: 220px !important;
    max-width: 320px !important;
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}
</style>
