var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hoverText
    ? _c("div", [
        _vm.hoverText.length > _vm.subString
          ? _c("span", {
              staticClass: "tooltip__text",
              class: {
                tooltipTop: _vm.position == "top",
                tooltipRight: _vm.position == "right",
                tooltipBottom: _vm.position == "bottom",
                tooltipLeft: _vm.position == "left"
              },
              domProps: { innerHTML: _vm._s(_vm.hoverText) }
            })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }